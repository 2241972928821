import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { class: "flex-grow-1 fw-bold" }
const _hoisted_4 = {
  key: 0,
  class: "p-3 text-muted"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  class: "p-3 text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_ClassificationRootNode = _resolveComponent("ClassificationRootNode")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName,
    style: {"z-index":"1"}
  }, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('SelectLabel')), 1),
            _createElementVNode("a", {
              href: "#",
              class: "small text-end",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.collapseAll && _ctx.collapseAll(...args)), ["prevent"]))
            }, _toDisplayString(_ctx.t('CollapseAllClassifications')), 1),
            (_ctx.isExpandFavoritesVisible)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: "#",
                  class: "small ms-2 text-end",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.expandAll && _ctx.expandAll(...args)), ["prevent"]))
                }, _toDisplayString(_ctx.t('ExpandAllFavorites')), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", {
            class: "overflow-auto border p-1",
            style: _normalizeStyle({ height: _ctx.height })
          }, [
            (_ctx.isLimitExceeded)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.t('SearchLimitExceeded')), 1))
              : (_ctx.nodes.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_ClassificationRootNode, {
                      items: _ctx.nodes,
                      "instance-id": _ctx.instance!.id,
                      t: _ctx.t
                    }, null, 8, ["items", "instance-id", "t"])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.isOnFavoritesView ? _ctx.t('NoFavoritesSelected') : _ctx.t('NoResults')), 1))
          ], 4)
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1)), [
    [_vShow, _ctx.componentOptions.visible]
  ])
}