
import _debounce from 'lodash/debounce';
import { computed, defineComponent, ref, watch } from 'vue';
import { DataType } from '@/classifications/api/configuration/application/DataType';
import { ComponentName } from '@/classifications/api/configuration/components/ComponentName';
import { setupComponent } from '@/classifications/composables/setupComponent';
import InjectStyles from '@/common/components/InjectStyles.vue';
import { ITreeOptions } from '@/classifications/api/configuration/components/ITreeOptions';
import ClassificationRootNode from '../common/ClassificationRootNode.vue';
import {
  AddNotificationCommand,
  CollapseAllCommand,
  ExpandAllCommand,
} from '@/classifications/api/runtime/CommandExecutor';
import {
  ClassificationsBrowserTreeTypeEnum,
  ClassificationsBrowserTreeViewEnum,
} from '@/common/services/swagger/index.defs';
import { NotificationType } from '@/common/api/runtime/INotification';
import { IData } from '@/classifications/api/runtime/IData';
import { ITreeTypeTabsOptions } from '@/classifications/api/configuration/components/ITreeTypeTabsOptions';

export default defineComponent({
  name: ComponentName.tree,

  components: {
    InjectStyles,
    ClassificationRootNode,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const {
      componentName,
      isReady,
      isWebComponent,
      instance,
      store,
      routeData,
      narrowerThan,
      emit,
      t,
    } = setupComponent(root, props.instanceId, [DataType.Classifications]);

    const validationWarnings = ref<string[]>([]);

    const displayModes = computed(() => {
      return (store.value?.options.components?.treeTypeTabs ?? ({} as ITreeTypeTabsOptions))
        ?.displayModes;
    });

    const componentOptions = computed(
      () => store.value?.options.components?.tree ?? ({} as ITreeOptions),
    );

    const nodes = computed(() => {
      if (
        isOnFavoritesView.value &&
        displayModes.value != null &&
        displayModes.value.indexOf(ClassificationsBrowserTreeViewEnum.ProductLine) < 0
      )
        return (
          store.value?.data.classifications?.items?.filter(
            (x) => x.tree != ClassificationsBrowserTreeTypeEnum.ProductLine,
          ) ?? []
        );
      else return store.value?.data.classifications?.items ?? [];
    });
    const isLimitExceeded = computed(() => store.value?.data.classifications?.limitExceeded);
    const isOnFavoritesView = computed(
      () => routeData.value?.view === ClassificationsBrowserTreeViewEnum.Favorites,
    );

    const isExpandFavoritesVisible = computed(
      () =>
        isOnFavoritesView.value &&
        store.value?.options.components?.searchToolbar?.expandFavoritesVisible,
    );

    const collapseAll = async () => {
      try {
        await instance.value?.execute(new CollapseAllCommand());
      } catch (error) {
        // ignored
      }
    };

    const expandAll = async () => {
      try {
        await instance.value?.execute(new ExpandAllCommand());
      } catch (error) {
        // ignored
      }
    };

    const height = computed(() =>
      narrowerThan('sm')
        ? componentOptions.value.mobileScrollHeight + 'px'
        : componentOptions.value.scrollHeight + 'px',
    );

    const onWarningsUpdated = _debounce(() => {
      try {
        if (validationWarnings.value.length) {
          instance.value?.execute(
            new AddNotificationCommand({
              message: validationWarnings.value.join('<br>'),
              type: NotificationType.warning,
              dismissAfter: 0,
            }),
          );
          validationWarnings.value = [];
        }
      } catch (error) {
        // Ignore error
      }
    }, 500);

    const unwatchInvalidSelectionsWatcher = watch(
      () => instance.value?.store.data.invalidSelectedItems,
      async () => {
        const invalidSelectedItems: IData['invalidSelectedItems'] =
          instance.value?.store.data.invalidSelectedItems;
        if (invalidSelectedItems && invalidSelectedItems?.length) {
          unwatchInvalidSelectionsWatcher();
          validationWarnings.value.push(
            t('InvalidSelectedItemsWarning', false, [
              invalidSelectedItems.map((item) => item.cid).join(', '),
            ]),
          );
          onWarningsUpdated();
        }
      },
      { immediate: true },
    );

    const unwatchInvalidFavoritesWatcher = watch(
      () => instance.value?.store.data.invalidFavoriteItems,
      async () => {
        const invalidFavoriteItems: IData['invalidFavoriteItems'] =
          instance.value?.store.data.invalidFavoriteItems;
        if (invalidFavoriteItems && invalidFavoriteItems?.length) {
          unwatchInvalidFavoritesWatcher();
          validationWarnings.value.push(
            t('InvalidFavoriteItemsWarning', false, [
              invalidFavoriteItems.map((item) => item.cid).join(', '),
            ]),
          );
          onWarningsUpdated();
        }
      },
      { immediate: true },
    );

    return {
      root,
      store,
      nodes,
      height,
      isReady,
      instance,
      routeData,
      componentName,
      isWebComponent,
      isLimitExceeded,
      componentOptions,
      isOnFavoritesView,
      isExpandFavoritesVisible,
      t,
      emit,
      collapseAll,
      expandAll,
    };
  },
});
